import axios from "axios";
import { registerAdminOneSignal } from "./api/admin";

const initOneSignal = async (appId, id) => {
  try {
    const OneSignal = window.OneSignal || [];
    OneSignal.push(function () {
      OneSignal.init({
        appId,
        notifyButton: {
          enable: true,
        },
        //allowLocalhostAsSecureOrigin: true,
        subdomainName: "api-orbifo",
      });
      OneSignal.push(function () {
        OneSignal.getUserId(function (userId) {
          if (userId) {
            axios(registerAdminOneSignal({ playerId: userId }))
              .then(() => console.log("ok"))
              .catch((e) => console.log(e));
          } else {
            OneSignal.push(function () {
              OneSignal.setExternalUserId(id);
            });
          }
        });
      });
    });
  } catch (error) {
    console.log(error);
  }
};

export { initOneSignal };

const registerAdminOneSignal = (data) => ({
  method: "post",
  url: `${process.env.REACT_APP_SERVER_ADDRESS}/admin/playerId`,
  headers: {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
    "Content-Type": "application/json",
  },
  data,
});

export { registerAdminOneSignal };

import React from 'react';
import * as Icon from 'react-feather';
import ReactCountryFlag from 'react-country-flag';
// import PerfectScrollbar from 'react-perfect-scrollbar';
import { UncontrolledDropdown, Dropdown, DropdownMenu, DropdownItem, DropdownToggle, Badge } from 'reactstrap';

import { history } from '../../../history';
import { IntlContext } from '../../../utility/context/Internationalization';

const handleNavigation = (e, path) => {
  e.preventDefault();
  history.push(path);
};

const UserDropdown = (props) => {
  return (
    <DropdownMenu right>
      <DropdownItem tag="a" href="#" onClick={(e) => handleNavigation(e, '/admin-panel/profile')}>
        <Icon.User size={14} className="mr-50" />
        <span className="align-middle">Edit Profile</span>
      </DropdownItem>
      <DropdownItem divider />
      <DropdownItem
        tag="a"
        href="/auth/login"
        onClick={(event) => {
          event.preventDefault();
          return props.logoutWithJWT();
        }}
      >
        <Icon.Power size={14} className="mr-50" />
        <span className="align-middle">Log Out</span>
      </DropdownItem>
    </DropdownMenu>
  );
};

class NavbarUser extends React.PureComponent {
  state = { langDropdown: false };

  handleLangDropdown = () => {
    this.setState({ langDropdown: !this.state.langDropdown });
  };

  render() {
    return (
      <ul className="nav navbar-nav navbar-nav-user float-right">
        <IntlContext.Consumer>
          {(context) => {
            let langArr = {
              en: 'English',
            };
            return (
              <Dropdown tag="li" className="dropdown-language nav-item" isOpen={this.state.langDropdown} toggle={this.handleLangDropdown} data-tour="language">
                <DropdownToggle tag="a" className="nav-link">
                  <ReactCountryFlag className="country-flag" countryCode={context.state.locale === 'en' ? 'us' : context.state.locale} svg />
                  <span className="d-sm-inline-block d-none text-capitalize align-middle ml-50">{langArr[context.state.locale]}</span>
                </DropdownToggle>
                <DropdownMenu right>
                  <DropdownItem tag="a" onClick={(e) => context.switchLanguage('en')}>
                    <ReactCountryFlag className="country-flag" countryCode="us" svg />
                    <span className="ml-1">English</span>
                  </DropdownItem>
                </DropdownMenu>
              </Dropdown>
            );
          }}
        </IntlContext.Consumer>

        <UncontrolledDropdown tag="li" className="dropdown-notification nav-item"></UncontrolledDropdown>
        <UncontrolledDropdown tag="li" className="dropdown-notification nav-item">
          <DropdownToggle tag="a" className="nav-link nav-link-label">
            <Icon.Bell size={21} />
            <Badge pill color="primary" className="badge-up"></Badge>
          </DropdownToggle>
          <DropdownMenu tag="ul" right className="dropdown-menu-media">
            <li className="dropdown-menu-header">
              <div className="dropdown-header mt-0">
                <h3 className="text-white">No Notification</h3>
                <span className="notification-title">App Notifications</span>
              </div>
            </li>

            {/*
               <PerfectScrollbar
              className="media-list overflow-hidden position-relative"
              options={{
                wheelPropagation: false,
              }}
            >
              <div className="d-flex justify-content-between">
                <Media className="d-flex align-items-start">
                  <Media left href="#">
                    <Icon.PlusSquare className="font-medium-5 primary" size={21} />
                  </Media>
                  <Media body>
                    <Media heading className="primary media-heading" tag="h6">
                      You have new order!
                    </Media>
                    <p className="notification-text">Are your going to meet me tonight?</p>
                  </Media>
                  <small>
                    <time className="media-meta" dateTime="2015-06-11T18:29:20+08:00">
                      9 hours ago
                    </time>
                  </small>
                </Media>
              </div>

              We can write custom notification to here above that as an example of notification

               </PerfectScrollbar>
              */}

            {/*
            <li className="dropdown-menu-footer">
              <DropdownItem tag="a" className="p-1 text-center">
                <span className="align-middle">Read all notifications</span>
              </DropdownItem>
            </li>
           */}
          </DropdownMenu>
        </UncontrolledDropdown>
        <UncontrolledDropdown tag="li" className="dropdown-user nav-item">
          <DropdownToggle tag="a" className="nav-link dropdown-user-link">
            <div className="user-nav d-sm-flex d-none">
              <span className="user-name text-bold-600">{this.props.userName}</span>
              <span className="user-status">Available</span>
            </div>
            <span data-tour="user">
              <img src={this.props.userImg} className="round" height="40" width="40" alt="avatar" />
            </span>
          </DropdownToggle>
          <UserDropdown {...this.props} />
        </UncontrolledDropdown>
      </ul>
    );
  }
}

export default NavbarUser;

import React from 'react';
import * as Icon from 'react-feather';
import { connect } from 'react-redux';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import { NavItem, NavLink, UncontrolledTooltip } from 'reactstrap';

import { history } from '../../../history';

// a little function to help us with reordering the bookmarks
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

class NavbarBookmarks extends React.PureComponent {
  state = {
    showBookmarks: false,
    // value: '',
    // noSuggestions: false,
    // isStarred: false,
    // suggestions: [],
    starredItems: [],
  };

  updateBookmarks = false;

  componentDidMount() {
    /* const {
      bookmarks: { suggestions, starred },
    } = this.props; */
    /* this.setState({
      suggestions,
      starredItems: starred,
    }); */
  }

  componentDidUpdate(_prevProps) {
    /*  if (prevProps.bookmarks.starred.length !== this.state.starredItems.length && this.updateBookmarks === true) {
      this.setState({ starredItems: this.props.bookmarks?.starred });
      this.updateBookmarks = false;
    } */
  }

  handleBookmarksVisibility = () => {
    this.setState({
      showBookmarks: !this.state.showBookmarks,
      value: '',
      suggestions: [],
      noSuggestions: false,
      starred: null,
    });
  };

  onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const starredItems = reorder(this.state.starredItems, result.source.index, result.destination.index);

    this.setState({
      starredItems,
    });
  };

  renderBookmarks = () => {
    this.updateBookmarks = true;
    return (
      <DragDropContext onDragEnd={this.onDragEnd}>
        <Droppable droppableId="droppable" direction="horizontal">
          {(provided, snapshot) => (
            /* <div ref={provided.innerRef} {...provided.droppableProps} className="d-flex flex-sm-wrap flex-lg-nowrap draggable-cards">
              {this.state.starredItems.map((item, index) => {
                const IconTag = Icon[item.icon ? item.icon : 'X'];
                return (
                  <Draggable key={item.target} draggableId={item.target} index={index}>
                    {(provided, snapshot) => {
                      return (
                        <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                          <NavItem className="nav-item d-none d-lg-block">
                            <NavLink tag="span" id={item.target} className="nav-link cursor-pointer" onClick={() => history.push(item.link)}>
                              <IconTag size={21} />
                            </NavLink>
                            <UncontrolledTooltip placement="bottom" target={item.target}>
                              {item.title}
                            </UncontrolledTooltip>
                          </NavItem>
                        </div>
                      );
                    }}
                  </Draggable>
                );
              })}
              {provided.placeholder}
            </div>
            */
            <div ref={provided.innerRef} className="d-flex flex-sm-wrap flex-lg-nowrap draggable-cards">
              <div ref={provided.innerRef}>
                <NavItem className="nav-item d-none d-lg-block">
                  <NavLink tag="span" id="calendar" className="nav-link cursor-pointer" onClick={() => history.push('/admin-panel/calendar')}>
                    <Icon.Calendar size={21} />
                  </NavLink>
                  <UncontrolledTooltip placement="bottom" target="calendar">
                    Calendar
                  </UncontrolledTooltip>
                </NavItem>
              </div>
            </div>
          )}
        </Droppable>
      </DragDropContext>
    );
  };

  render() {
    const {
      //    bookmarks: { extraStarred, suggestions },
      sidebarVisibility,

      // handleAppOverlay,
    } = this.props;

    // const renderExtraStarred = null;
    /*
     = extraStarred.length > 0
        ? extraStarred.map((i) => {
            const IconTag = Icon[i.icon ? i.icon : null];
            return (
              <DropdownItem key={i.id} href={i.link}>
                <IconTag size={15} />
                <span className="align-middle ml-1">{i.title}</span>
              </DropdownItem>
            );
          })
        : null;
        */

    return (
      <div className="mr-auto float-left bookmark-wrapper d-flex align-items-center">
        <ul className="navbar-nav d-xl-none">
          <NavItem className="mobile-menu mr-auto">
            <NavLink className="nav-menu-main menu-toggle hidden-xs is-active" onClick={sidebarVisibility}>
              <Icon.Menu className="ficon" />
            </NavLink>
          </NavItem>
        </ul>
        <ul className="nav navbar-nav bookmark-icons">
          {this.renderBookmarks()}
          {/* extraStarred?.length > 0 ? (
            <NavItem>
              <NavLink tag="div">
                <UncontrolledDropdown>
                  <DropdownToggle tag="span">
                    <Icon.ChevronDown />
                  </DropdownToggle>
                  <DropdownMenu right>{renderExtraStarred}</DropdownMenu>
                </UncontrolledDropdown>
              </NavLink>
            </NavItem>
          ) : null */}
        </ul>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {};
};

export default connect(mapStateToProps, {})(NavbarBookmarks);
